import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { Dialog } from '@headlessui/react';
import { Company } from '../types/company';
import { FormattedMessage } from 'react-intl';
import { IconMap } from '@allbin/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from './Button';
import { prettifyPhoneNumber } from '../utils/helpers';
import { useUnits } from '../hooks/useUnits';
import { useFloors } from '../hooks/useFloors';
import { Modal } from './Modal';
import { useFeatures } from '../hooks/useFeatures';
import { useInteractionTimeout } from '../hooks/useInteractionTimeout';
import { useConfig } from '../providers/ConfigProvider';
import QRCode from 'qrcode';

interface Props {
  companies: Company[];
  show: boolean;
  onClose: () => void;
}

export const CompanyModal: FC<Props> = ({ companies, show, onClose }) => {
  const { interactionTimeout } = useConfig();
  useInteractionTimeout(interactionTimeout - 2000, onClose);

  if (!show) {
    return null;
  }

  return (
    <Modal open={true} onClose={onClose} className="w-4/5">
      <div className="flex w-full flex-col gap-12">
        {companies
          .slice()
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((company, index) => (
            <Fragment key={company.name}>
              {index !== 0 ? (
                <div className="mx-auto h-1 w-11/12 rounded bg-gray-200" />
              ) : null}
              <CompanyInfo company={company} />
            </Fragment>
          ))}
      </div>
    </Modal>
  );
};

interface CompanyInfoProps {
  company: Company;
}
const CompanyInfo: FC<CompanyInfoProps> = ({ company }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { data: units } = useUnits();
  const { data: floors } = useFloors();
  const { data: feature_collection } = useFeatures();
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const { logo, name, description, contacts, url } = company;

  useEffect(() => {
    if (!url) {
      return;
    }
    const loadQrCode = (): void => {
      QRCode.toCanvas(
        canvasRef.current,
        url,
        { errorCorrectionLevel: 'low', scale: 10 },
        (err) => {
          if (err) {
            throw err;
          }
        },
      );
    };
    loadQrCode();
  }, [url]);

  const unit = useMemo(() => {
    if (!units) {
      return undefined;
    }

    return units.find(({ tenants }) =>
      tenants.find((t) => {
        if (t.type !== 'company') {
          return;
        }

        return t.company.name === name;
      }),
    );
  }, [units, name]);

  const isOnFloorPlan = useMemo(() => {
    if (!floors || !feature_collection) {
      return false;
    }

    return floors.some((floor) =>
      feature_collection.features.some(
        (f) => f.properties?.['floor_id'] === floor.id,
      ),
    );
  }, [floors, feature_collection]);

  const onClickToMap = useCallback(() => {
    if (!unit) {
      return;
    }
    navigate(`/floorplan?unitId=${unit.id}`);
  }, [unit, navigate]);

  return (
    <div className="flex flex-col gap-6">
      <div className="flex items-center gap-5">
        {logo ? (
          <div className="flex h-16 w-16">
            <img
              src={logo}
              alt="company logo"
              className="h-full w-full object-contain"
            />
          </div>
        ) : null}
        <Dialog.Title as="h3" className="text-3xl font-medium text-gray-900">
          {name}
        </Dialog.Title>
      </div>
      {contacts.length > 0 ? (
        <div className="flex flex-col gap-2">
          <p className="text-gray">
            <FormattedMessage defaultMessage="Kontaktpersoner" />
          </p>
          <div className="grid grid-cols-[repeat(4,auto)] gap-1 text-lg text-gray-medium">
            {contacts.map(({ name, title, phone, email }) => (
              <Fragment key={name}>
                <span className="text-gray-dark">{name}</span>
                <span>{title}</span>
                <span>{phone ? prettifyPhoneNumber(phone) : null}</span>
                <span className="text-right">{email}</span>
              </Fragment>
            ))}
          </div>
        </div>
      ) : null}
      {description ? (
        <div className="flex flex-col gap-2">
          <p className="text-gray">
            <FormattedMessage defaultMessage="Om oss" />
          </p>
          <p className="whitespace-pre-wrap text-base text-gray-dark">
            {description}
          </p>
        </div>
      ) : null}
      <div className="flex items-end">
        {url ? <canvas ref={canvasRef} id="canvas" className="-m-6" /> : null}
        {isOnFloorPlan && !location.pathname.includes('floorplan') ? (
          <Button
            className="ml-auto"
            startIcon={<IconMap />}
            label={
              <FormattedMessage defaultMessage="Hitta oss på planskissen" />
            }
            onClick={onClickToMap}
          />
        ) : null}
      </div>
    </div>
  );
};
