import React, { FC, useMemo } from 'react';
import { TenantListSortOption } from '../../utils/TenantUtils';
import { IconBookmark, IconUser } from '@allbin/icons';
import { useDeviceOrientation } from '../../hooks/useDeviceOrientation';
import { getShortFloorLabel } from '../../utils/helpers';
import { TenantWithFloor } from '../../types/unit';

interface TenantListItemProps {
  tenant: TenantWithFloor;
  sort?: TenantListSortOption;
  onClick?: (tenant: TenantWithFloor) => void;
}

const TenantListItem: FC<TenantListItemProps> = ({
  tenant,
  sort = 'last_name',
  onClick,
}) => {
  const { portrait } = useDeviceOrientation();

  const isCompany = tenant.type === 'company';

  const name1 = useMemo(() => {
    if (isCompany) {
      return tenant.company.name;
    }

    const { first_name, last_name } = tenant.individual;
    if (!last_name) {
      return first_name;
    }

    return sort === 'name' ? first_name : last_name;
  }, [isCompany, sort, tenant]);

  const name2 = useMemo(() => {
    if (isCompany) {
      return '';
    }

    const { first_name, last_name } = tenant.individual;
    if (!last_name) {
      return '';
    }
    return sort === 'name' ? last_name : first_name;
  }, [isCompany, sort, tenant]);

  return (
    <li
      className="flex items-center gap-4 rounded border border-gray-medium px-4 py-2"
      onClick={() => onClick?.(tenant)}
    >
      <div
        className={`flex items-center ${
          portrait ? 'size-10 min-w-10' : 'size-7 min-w-7'
        }`}
      >
        {isCompany ? (
          <>
            {tenant.company.logo ? (
              <img
                src={tenant.company.logo}
                alt="logo"
                className="h-full w-full object-contain rounded"
              />
            ) : (
              <IconBookmark className="h-full w-full" />
            )}
          </>
        ) : (
          <IconUser className="h-full w-full" />
        )}
      </div>
      <div className={`grow truncate ${portrait ? 'text-lg' : ''}`}>
        <span className={sort !== 'floor' ? 'font-bold' : ''}>{name1}</span>
        {name2 && (
          <>
            {sort !== 'name' && ','}
            <span className="ml-1">{name2}</span>
          </>
        )}
      </div>
      {!isCompany && tenant.object_id ? (
        <span className="w-16 text-right">{tenant.object_id}</span>
      ) : null}
      {sort !== 'floor' ? (
        <span>{getShortFloorLabel(tenant.floor_nr.toString())}</span>
      ) : null}
    </li>
  );
};

export default TenantListItem;
