import React, { ReactNode, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  IconBuilding,
  IconBusStop,
  IconCloudSunny,
  IconCutlery,
  IconLeaf,
  IconMap,
  IconMultiplePages,
  IconWhiteFlag,
} from '@allbin/icons';
import { useAttachments } from './useAttachments';
import { useEmbeddedUrls } from './useEmbeddedUrls';
import { useTenants } from './useTenants';
import { useLocationInternal } from './useLocation';
import { useFloors } from './useFloors';
import { useServices } from './useServices';
import { usePublicTransit } from '../store/usePublicTransit';
import { EmbedIcon } from '../components/EmbedIcon';

export interface LinkProps {
  icon: ReactNode;
  label: ReactNode;
  to: string;
}

export const useLinks = (): LinkProps[] => {
  const { data: location } = useLocationInternal();
  const { departures } = usePublicTransit(
    useCallback(({ departures }) => ({ departures }), []),
  );
  const { data: attachments } = useAttachments();
  const { data: embeddedUrls } = useEmbeddedUrls();
  const { data: floors } = useFloors();
  const { data: services } = useServices();
  const { tenantLinkProps } = useTenants();

  const links = useMemo(() => {
    const links: LinkProps[] = [
      {
        ...tenantLinkProps,
        to: '/tenants',
      },
    ];
    if (floors && floors.some((f) => f.floor_plan)) {
      links.push({
        icon: <IconMap />,
        label: <FormattedMessage defaultMessage="Planskiss" />,
        to: '/floorplan',
      });
    }
    if (location?.coordinate) {
      if (Object.keys(departures).length > 0) {
        links.push({
          icon: <IconBusStop />,
          label: <FormattedMessage defaultMessage="Kollektivtrafik" />,
          to: '/publictransit',
        });
      }
      if (services && services.length > 0) {
        links.push({
          icon: <IconWhiteFlag />,
          label: <FormattedMessage defaultMessage="Servicekarta" />,
          to: '/services',
        });
      }
      links.push({
        icon: <IconCloudSunny />,
        label: <FormattedMessage defaultMessage="Väder" />,
        to: '/weather',
      });
    }
    if (
      attachments?.['energy-declaration-ovk'] &&
      attachments?.['energy-declaration-ovk'].length > 0
    ) {
      links.push({
        icon: <IconLeaf />,
        label: <FormattedMessage defaultMessage="Energideklaration / OVK" />,
        to: '/energy-declaration-ovk',
      });
    }
    if (
      attachments?.['property-info'] &&
      attachments?.['property-info'].length > 0
    ) {
      links.push({
        icon: <IconBuilding />,
        label: <FormattedMessage defaultMessage="Fastighetsinfo" />,
        to: '/property-info',
      });
    }
    if (
      attachments?.['restaurant-menu'] &&
      attachments?.['restaurant-menu'].length > 0
    ) {
      links.push({
        icon: <IconCutlery />,
        label: <FormattedMessage defaultMessage="Restaurang-menyer" />,
        to: '/restaurant-menu',
      });
    }
    if (attachments?.['other'] && attachments?.['other'].length > 0) {
      links.push({
        icon: <IconMultiplePages />,
        label: <FormattedMessage defaultMessage="Dokument" />,
        to: '/documents',
      });
    }
    if (embeddedUrls && embeddedUrls.length > 0) {
      embeddedUrls.forEach((embed) => {
        links.push({
          icon: <EmbedIcon icon={embed.icon} />,
          label: embed.label,
          to: `/embedded-urls/${embed.id}`,
        });
      });
    }

    return links;
  }, [
    departures,
    tenantLinkProps,
    floors,
    location?.coordinate,
    attachments,
    embeddedUrls,
    services,
  ]);

  return links;
};
